<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import FilterModal from "@/components/modals/filter-modal.vue";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import ListingTable from "@/components/listings/listing-table";

export default {
	components: {
		Layout,
		PageHeader,
		FilterModal,
		ConfirmationModal,
		MessageModal,
		InputGroup,
		SelectGroup,
		ListingTable,
	},
	data() {
		return {
			title: "PARENT REQUESTS",
			items: [
				{
					text: "Parent Requests",
				},
			],
			filter: {
				requestor_name: '',
				phone: '',
				student_name: '',
				level: '',
				type_of_lesson: 3,
				status: 3,
				service: 'all'
			},
			fields: [
				{ key: "id", sortable: true, label: "ID" },
				{ key: "requestor_name", sortable: true, label: "Requestor name" },
				{ key: "phone", sortable: true, label: "Contact number" },
				{ key: "student_name", sortable: true, label: "Student name" },
				{ key: "student_level", sortable: true, label: "Level / Grade" },
				{ key: "type_of_lesson", sortable: true, label: "Type of Lesson" },
				{ key: "status", label: "Status" },
				{ key: "action" },
			],
			gradeOptions: [],
            typeOptions: [
                { name: 'All', value: 3 },
                { name: 'Online', value: 2 },
                { name: 'Physical', value: 1 },
            ],
            statusOptions: [
                { name: 'All', value: 3 },
				{ name: 'Pending', value: 2 },
                { name: 'Approved', value: 1 },
                { name: 'Rejected', value: 0 },
            ],
			serviceOptions: [],
			addUrl: `${window.location.origin}/parent_request/form/add`,
			isModalVisible: false,
		};
	},
	async mounted() {
		this.retrieveGrades().then((grades) => {
			this.gradeOptions = grades.map((grade) => ({
				name: grade.name,
				value: grade.name,
			}));
		});

		this.retrieveServices().then((services) => {
			this.serviceOptions = services.map((service) => ({
				name: service.name,
				value: service.id,
			}));

			// set All as first one
			this.serviceOptions.unshift({
				name: 'All',
				value: 'all',
			});
		});
	},
	methods: {
		async retrieveGrades() {
			let grades = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/student_grade`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    grades = result['data'].student_grades;
                }
            } catch (e) {
                console.error(e);
            }

            return grades;
		},
		async retrieveServices() {
			let services = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    services = result['data'].services;
                }
            } catch (e) {
                console.error(e);
            }

            return services;
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		deleteRecord(id) {
			const deleteCallback = async () => {
				await axios.delete(`${process.env.VUE_APP_APIURL}/assignment/${id}`, {
					headers: authHeader(),
				});
				this.filterTable(this.filter);
				this.$refs.confirmationModal.closeModal();
				this.$refs.messageModal.showModal("The record has been deleted");
			};
			this.$refs.confirmationModal.showModal(
				`Confirm delete parent request (ID: ${id})?`,
				deleteCallback
			);
		},
		filterTable(filters) {
			const filter = JSON.parse(JSON.stringify(filters));
			this.$refs.listingTable.filterTable(filter);
		},
		resetTable() {
			this.filter = {
				requestor_name: '',
				phone: '',
				student_name: '',
				level: '',
				type_of_lesson: 3,
				status: 3,
				service: 'all'
			};
			this.$refs.listingTable.filterTable(this.filter);
		},
		showFilterModal() {
			this.$refs.filterModal.showModal();
		},
		hideModal() {
			this.isModalVisible = false;
		},
		handleSubmit() {
			this.filterTable(this.filter);
			this.$refs.filterModal.closeModal();
		}
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
		
		<FilterModal ref="filterModal" :isVisible="isModalVisible" @close="hideModal">
			<form @submit.prevent="handleSubmit">
				<div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.requestor_name" type="text" id="requestor_name" displayName="Requestor name" />
                        </div>
                    </div>

					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.phone" type="text" id="phone" displayName="Contact number" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.student_name" type="text" id="student_name" displayName="Student name" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.level" id="level" displayName="Level / Grade" :options="gradeOptions" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.type_of_lesson" id="type_of_lesson" displayName="Type of lesson" :options="typeOptions" />
                        </div>
                    </div>
					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.service" id="service" displayName="Type of service" :options="serviceOptions" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.status" id="status" displayName="Status" :options="statusOptions" />
                        </div>
                    </div>
				</div>

				<div class="modal-footer">
                    <button type="button" class="clear__btn" @click="resetTable">Clear</button>
                    <button type="submit" class="save__btn">Filter</button>
                </div>
			</form>
		</FilterModal>

		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body pt-0" style="position: relative;">
						<div class="filter_buttons">
							<button class="filter_button" @click="showFilterModal">
								<i class="mdi mdi-filter-variant mr-2 font-size-17"></i>Filter
							</button>
							<a class="add_new_button" :href="addUrl">
                                <i class="mdi mdi-plus mr-2 font-size-17"></i> Add New Parent Request
                            </a>
						</div>

						<ListingTable ref="listingTable" :fields="fields" path="assignment" listName="assignments">
							<template v-slot:cell(status)="row">
								<div :class="{
									'status-blue': row.value === 'pending',
									'status-red': row.value === 'rejected',
									'status-green': row.value === 'approved',
								}">
									{{ row.value === 'approved' ? 'Approved' : (row.value === 'rejected' ? 'Rejected' : 'Pending') }}
								</div>
							</template>

							<template v-slot:cell(type_of_lesson)="row">
                                {{ capitalizeFirstLetter(row.value) }}
                            </template>

							<template v-slot:cell(action)="{ item }">
								<router-link class="mr-2 text-primary"
									:to="{ name: 'Parent Request View', params: { id: item.id } }">
									<i class="mdi mdi-eye font-size-18" v-b-tooltip.hover title="View"></i>
								</router-link>

								<router-link class="mr-2 text-primary"
									:to="{ name: 'Parent Request Form', params: { id: item.id } }"><i
										class="mdi mdi-pencil font-size-18" v-b-tooltip.hover
										title="Edit"></i>
								</router-link>
								<a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
									@click="deleteRecord(item.id)">
									<i class="mdi mdi-trash-can font-size-18"></i>
								</a>
							</template>
						</ListingTable>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<style scoped>
.modal-footer {
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    justify-content: space-between;
    border-top: 1px solid rgba(205, 205, 205, 0.5);

    & .clear__btn {
        border: 0;
        background-color: transparent;
        font-family: Nunito;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #505D69;
        text-decoration: underline;
    }

    & .save__btn {
        border: 1px solid #004AAD;
        border-radius: 6px;
        background: #004AAD;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
}
</style>